import { useEffect } from 'react';

const usePaymentRequest = (
  setPaymentProduct,
  setIsVisible,
  setShowConnectWallet,
  addEventListener,
  removeEventListener
) => {
  useEffect(() => {
    const handlePaymentRequest = async (message) => {
      let productInfo;
      try {
        const parsed = JSON.parse(message);
        productInfo = parsed;
      } catch (e) {
        productInfo = {
          productId: message,
        };
      }
      setPaymentProduct(productInfo);
      setShowConnectWallet(false);
      setIsVisible(true);
    };

    addEventListener('PaymentRequest', handlePaymentRequest);
    return () => removeEventListener('PaymentRequest', handlePaymentRequest);
  }, [
    addEventListener,
    removeEventListener,
    setPaymentProduct,
    setIsVisible,
    setShowConnectWallet,
  ]);
};

export default usePaymentRequest;
