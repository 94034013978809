import React, { useEffect } from 'react';

import styles from './Modal.module.css';

const Modal = ({ isVisible, onClose, title, children }) => {
  useEffect(() => {
    document.body.style.overflow = isVisible ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div className={`${styles.modal} ${isVisible ? styles.visible : ''}`}>
      <div className={styles.content}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        {title && <div className={styles.title}>{title}</div>}

        <div className={styles.body}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
