import { useEffect, useState } from 'react';
import useAdsgram from './useAdsgram';

const useShowRewardedAd = (
  isLoaded,
  sendMessage,
  addEventListener,
  removeEventListener
) => {
  const showAd = useAdsgram();
  const [rewardResult, setRewardResult] = useState(null);

  useEffect(() => {
    if (!!isLoaded && !!rewardResult) {
      sendMessage(
        'TeleLauncherSDK',
        'OnRewardedAdResult',
        JSON.stringify(rewardResult)
      );
      setRewardResult(null);
    }
  }, [isLoaded, rewardResult, sendMessage]);

  useEffect(() => {
    const handleShowRewardedAd = async (message) => {
      const result = await showAd();
      setRewardResult(result);
    };
    addEventListener('ShowRewardedAd', handleShowRewardedAd);
    return () => removeEventListener('ShowRewardedAd', handleShowRewardedAd);
  }, [addEventListener, removeEventListener, showAd]);
};

export default useShowRewardedAd;
