import { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import ReactGA from 'react-ga4';
import { THEME } from '@tonconnect/ui';

import { getAllCache } from './api/allCache';
import { getSave } from './api/saves';

import { DEFAULT_USER, GA4_KEY, storageKey } from './utils/constants';
import { preloadMedia } from './utils/preloadService';
import {
  cloudGetItem,
  cloudSetItem,
  localGetItem,
  localSetItem,
} from './utils/cloudStorage';
import GAMES_CONFIG from './utils/GamesConfig';
import { promoHandlerUtility } from './utils/promoHandlerUtility';
import { sendGTMEvent } from './utils/analytics';

import GamePage from './pages/GamePage/GamePage';

import Loading from './components/Loading/Loading';

import LoadingLuckyVerse from './gamesSrc/luckyVerse/componentsLuckyVerse/LoadingLuckyVerse/LoadingLuckyVerse';

const theme = {
  colors: {
    primary: '#6200ea',
    secondary: '#03dac6',
    background: '#f0f0f0',
    text: '#000000',
  },
  fonts: {
    body: 'Arial, sans-serif',
  },
};

const App = () => {
  const [isCacheCleared, setIsCacheCleared] = useState(false);
  const [balance, setBalance] = useState(null);
  const searchParams = new URLSearchParams(window.location.search);
  const appId = searchParams.get('app_id');
  const [isVerticalOrientation, setIsVerticalOrientation] = useState(false);

  useEffect(() => {
    const handleSizeChange = () => {
      setIsVerticalOrientation(window.innerHeight > window.innerWidth);
    };

    handleSizeChange();

    window.addEventListener('orientationchange', handleSizeChange);
    window.addEventListener('resize', handleSizeChange);

    return () => {
      window.removeEventListener('orientationchange', handleSizeChange);
      window.removeEventListener('resize', handleSizeChange);
    };
  }, []);

  useEffect(() => {
    // TODO uncomment later
    if (GAMES_CONFIG[appId || 1].fullScreen) {
      window.Telegram.WebApp.requestFullscreen();
      window.Telegram.WebApp.enableClosingConfirmation();
    }

    const ga_key = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;
    if (ga_key) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
      ReactGA.send('loading');
    }
  }, []);

  const clearAllCaches = useCallback(async () => {
    const allcache = await caches.keys();
    await Promise.all(allcache.map((key) => caches.delete(key)));
  }, []);

  const handleUnityCache = useCallback(
    async (appId) => {
      try {
        const allCache = await getAllCache(appId);
        const unityCacheLocal = localGetItem(
          `${storageKey.UNITY_CACHE}${appId}`
        );

        if (
          !allCache ||
          !unityCacheLocal ||
          unityCacheLocal !== allCache.etag
        ) {
          if (allCache?.etag) {
            localSetItem(`${storageKey.UNITY_CACHE}${appId}`, allCache.etag);
          }
          await clearAllCaches();
          sendGTMEvent(GA4_KEY.unity_update);
        }

        const savedCache = await cloudGetItem(storageKey.SAVE_CACHE);

        if (savedCache === allCache.save_hashsum) {
          return;
        }

        const data = await getSave(
          window.Telegram.WebApp.initDataUnsafe.user.id || DEFAULT_USER.id,
          appId
        );

        if (data?.data) {
          await Promise.all([
            cloudSetItem(storageKey.SAVE_DATA, JSON.stringify(data.data)),
            cloudSetItem(storageKey.SAVE_CACHE, allCache.save_hashsum),
          ]);
        }
      } catch (error) {
        console.error('Error while processing Unity cache:', error);
        await clearAllCaches();
      }
    },
    [clearAllCaches]
  );

  useEffect(() => {
    const initializeApp = async () => {
      try {
        console.log('initializeApp');
        await handleUnityCache(appId);
        await preloadMedia();

        setIsCacheCleared(true);
      } catch (error) {
        console.error('Error during application initialization:', error);
        setIsCacheCleared(true);
      }
    };

    const telegram = window.Telegram.WebApp;
    if (telegram) {
      telegram.setHeaderColor('rgb(60, 157, 226)');
      telegram.setBackgroundColor('rgb(60, 157, 226)');

      window.scrollTo(0, 0);
      telegram.expand();
    }
    initializeApp();
    // promoHandlerUtility.sendPromoCallback(
    //   window.Telegram.WebApp.initDataUnsafe.user?.id || DEFAULT_USER.id
    // );
  }, [handleUnityCache, appId]);

  const manifestUrl = `${
    process.env.REACT_APP_SERVICE_URL
  }/tonconnect-manifest.json?app_id=${appId || 1}`;

  const uiPreferences = {
    theme: THEME.LIGHT,
  };

  return (
    <ThemeProvider theme={theme}>
      <TonConnectUIProvider
        manifestUrl={manifestUrl}
        actionsConfiguration={{
          twaReturnUrl: `${GAMES_CONFIG[appId || 1].telegramUrl}?startapp=${
            window.Telegram.WebApp.initDataUnsafe.user?.id || DEFAULT_USER.id
          }`,
        }}
        uiPreferences={uiPreferences}
      >
        {!isCacheCleared ? (
          +appId == 1 ? (
            <LoadingLuckyVerse
              setBalance={setBalance}
              gameProgress={0}
              setFullLoaded={() => {}}
              isInitialLoading={true}
              isVerticalOrientation={isVerticalOrientation}
            />
          ) : (
            <Loading
              setBalance={setBalance}
              gameProgress={0}
              setFullLoaded={() => {}}
              isInitialLoading={true}
            />
          )
        ) : (
          <Router>
            <Routes>
              <Route
                exact
                path='/'
                element={
                  <GamePage
                    initialBalance={balance}
                    isVerticalOrientation={isVerticalOrientation}
                  />
                }
              />
            </Routes>
          </Router>
        )}
      </TonConnectUIProvider>
    </ThemeProvider>
  );
};

export default App;
