import React, { useEffect, useState } from 'react';

import styles from './Tappads.module.css';

const useTappadsSDK = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [tasks, setTasks] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const initializeSDK = async () => {
    if (isInitialized) return;

    try {
      await window.TappAdsPubSdk.init('973314c8-d009-4432-9222-9b1b4e24ce6e', {
        debug: true,
      });
      setIsInitialized(true);
    } catch (err) {
      setError('Failed to initialize TappAds');
      console.error('TappAdsPubSdk initialization error:', err);
    }
  };

  const loadTasks = async () => {
    try {
      await initializeSDK();

      const feed = await window.TappAdsPubSdk.getFeed();
      const taskIds = feed.map((task) => task.id);
      const statuses = await window.TappAdsPubSdk.getTaskStatus(taskIds);

      const newTasks = feed.filter((task) => statuses[task.id] === 'new');
      setTasks(newTasks);
    } catch (err) {
      setError('Failed to load tasks');
      console.error('Failed to load tasks:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const startTask = async (taskId) => {
    try {
      await window.TappAdsPubSdk.taskStart(taskId);
      return true;
    } catch (err) {
      console.error('Failed to start task:', err);
      return false;
    }
  };

  return { tasks, error, isLoading, loadTasks, startTask };
};

const Tappads = ({ setIsModalVisible, setAdNumber, sendMessage }) => {
  const { tasks, error, isLoading, loadTasks, startTask } = useTappadsSDK();

  useEffect(() => {
    loadTasks();
  }, []);

  const handleTaskClick = async (task) => {
    // const success = await startTask(task.id);
    // if (success) {
    //   sendMessage(
    //     'TeleLauncherSDK',
    //     'OnAdsResult',
    //     JSON.stringify({
    //       done: true,
    //     })
    //   );
    //   setAdNumber(null);
    //   setIsModalVisible(false);
    // }
    console.log('start');
  };

  return (
    <div className={styles.tappadsContainer}>
      {isLoading && (
        <div className={styles.loaderWrapper}>
          <div className={styles.loader}>
            <div className={styles.spinnerBorder} role='status' />
          </div>
        </div>
      )}

      {error && (
        <div className={styles.messageWrapper}>
          <div className={styles.errorMessage}>{error}</div>
        </div>
      )}

      {!isLoading && !error && (!tasks || tasks.length === 0) && (
        <div className={styles.messageWrapper}>
          <div className={styles.errorMessage}>
            Sorry, we don't have ads for you at the moment.
          </div>
        </div>
      )}

      {!isLoading && !error && tasks && tasks.length > 0 && (
        <div className={styles.tasksList}>
          {tasks.map((task) => (
            <div
              key={task.id}
              className={styles.taskCard}
              onClick={() => handleTaskClick(task)}
            >
              <img
                src={task.icon}
                alt={task.name}
                className={styles.taskIcon}
              />
              <div className={styles.taskInfo}>
                <h3 className={styles.taskTitle}>{task.name}</h3>
                <p className={styles.taskDescription}>{task.description}</p>
                <div className={styles.taskReward}>
                  Reward: ${task.payout.toFixed(2)}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Tappads;
