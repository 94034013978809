import React, { useEffect, useState, useRef } from 'react';

import useUrlParams from '../../../../hooks/useUrlParams';

import { getBalance } from '../../../../api/balance';
import { createUser, getAuth, setPhoto } from '../../../../api/auth';

import {
  cloudGetItem,
  localGetItem,
  localSetItem,
} from '../../../../utils/cloudStorage';
import { GA4_KEY, storageKey } from '../../../../utils/constants';
import { sendGTMEvent } from '../../../../utils/analytics';

import OnboardingLuckyVerse from '../OnboardingLuckyVerse/OnboardingLuckyVerse';

import styles from './LoadingLuckyVerse.module.css';

const PROGRESS_STEP = 0.5;
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

function LoadingLuckyVerse({
  setBalance,
  gameProgress,
  setFullLoaded,
  isInitialLoading = false,
  isVerticalOrientation,
}) {
  const { app_id } = useUrlParams();
  const [displayProgress, setDisplayProgress] = useState(0);
  const [targetProgress, setTargetProgress] = useState(0);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const animationRef = useRef(null);

  const updateProgress = (step, text) => {
    if (!isInitialLoading) {
      setTargetProgress(step);
    }
  };

  const initializeApp = async (retryAttempt = 0) => {
    try {
      let token = localGetItem('Token');
      const telegram = window.Telegram.WebApp;
      const userData = telegram.initDataUnsafe.user;

      if (!token) {
        updateProgress(10, 'Authenticating...');
        await new Promise((resolve) => setTimeout(resolve, 500));
        const authData = await getAuth(userData);
        token = authData.token;
        localSetItem('Token', token, 3600 * 24);
        updateProgress(20, 'Authentication complete');
      } else {
        updateProgress(20, 'Using existing authentication');
      }

      await new Promise((resolve) => setTimeout(resolve, 500));
      updateProgress(30, 'Registering user...');
      cloudGetItem(storageKey.IS_CREATED).then(async (item) => {
        if (!item) {
          await createUser(userData);
        }
      });

      if (userData.photo_url) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        updateProgress(70, 'Updating user data...');
        await setPhoto(userData);
      }

      await new Promise((resolve) => setTimeout(resolve, 500));
      updateProgress(85, 'Fetching balance...');
      const balanceData = await getBalance(userData.id);
      setBalance(balanceData.balance);

      updateProgress(100, 'Loading game...');
    } catch (error) {
      console.error(
        `Initialization attempt ${retryAttempt + 1} failed:`,
        error
      );

      if (retryAttempt < MAX_RETRIES) {
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        return initializeApp(retryAttempt + 1);
      }

      updateProgress(100, `Error: ${error.message}`);
    }
  };

  useEffect(() => {
    const animateProgress = () => {
      setDisplayProgress((prev) => {
        if (isInitialLoading) return 0;

        const nextValue = Math.min(
          prev + PROGRESS_STEP,
          Math.max(targetProgress, gameProgress)
        );

        if (nextValue < Math.max(targetProgress, gameProgress)) {
          animationRef.current = requestAnimationFrame(animateProgress);
        }

        return nextValue;
      });
    };

    animationRef.current = requestAnimationFrame(animateProgress);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [targetProgress, gameProgress, isInitialLoading]);

  useEffect(() => {
    if (!isInitialLoading) {
      const telegram = window.Telegram.WebApp;
      if (telegram) {
        telegram.setHeaderColor('rgb(60, 157, 226)');
        telegram.setBackgroundColor('rgb(60, 157, 226)');

        window.scrollTo(0, 0);
        telegram.expand();
        initializeApp(0);
        sendGTMEvent(GA4_KEY.unity_initializate);
      }

      return () => {
        if (telegram) {
          telegram.setHeaderColor('#000000');
          telegram.setBackgroundColor('#000000');
        }
      };
    }
  }, [app_id, setBalance, isInitialLoading]);

  useEffect(() => {
    if (displayProgress >= 98 && gameProgress >= 98) {
      setShowOnboarding(true);
    }
  }, [displayProgress, gameProgress, setFullLoaded]);

  const totalProgress = isInitialLoading
    ? 0
    : Math.round((displayProgress + gameProgress) / 2);

  return (
    <div className={styles.playScreen}>
      <img
        className={styles.image}
        style={
          isVerticalOrientation
            ? { height: '100dvh', width: 'auto' }
            : { width: '100dvw', height: 'auto' }
        }
        src='/assetsLuckyVerse/LoadingScreen.webp'
        alt='Game logo'
      />

      <div className={styles.container}>
        <div className={styles.progressContainer}>
          <div
            className={styles.progress}
            style={{ width: `${Math.min(100, Math.max(0, totalProgress))}%` }}
          />
        </div>
      </div>
      {showOnboarding && <OnboardingLuckyVerse setFullLoaded={setFullLoaded} />}
    </div>
  );
}

export default LoadingLuckyVerse;
