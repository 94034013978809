import { useCallback, useEffect } from 'react';

import useUrlParams from './useUrlParams';

import { getSave } from '../api/saves';

import { cloudGetItem, cloudSetItem } from '../utils/cloudStorage';
import { DEFAULT_USER, GA4_KEY, storageKey } from '../utils/constants';
import { sendGTMEvent } from '../utils/analytics';
import GAMES_CONFIG from '../utils/GamesConfig';

const useInitializeApp = (
  isLoaded,
  sendMessage,
  balance,
  addEventListener,
  removeEventListener
) => {
  const { app_id } = useUrlParams();

  const initializeApp = useCallback(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const user = window.Telegram.WebApp.initDataUnsafe.user || DEFAULT_USER;
      const token = JSON.parse(localStorage.getItem('Token'));

      const urlParams = new URLSearchParams(window.location.search);
      let referer_id = urlParams.get('tgWebAppStartParam');
      
      sendMessage(
        'TeleLauncherSDK',
        'OnSafeAreaUpdated',
        JSON.stringify({
          safeAreaInset: window.Telegram.WebApp.safeAreaInset,
          contentSafeAreaInset: window.Telegram.WebApp.contentSafeAreaInset,
        })
      );

      sendMessage(
        'TeleLauncherSDK',
        'InitializeApp',
        JSON.stringify({
          app_id,
          app_url: GAMES_CONFIG[app_id || 1].assertsUrl || '',
          user: {
            ...user,
            user_token: token?.value,
            reffer_id: referer_id || '',
            photo_url:
              user?.photo_url ||
              `${process.env.REACT_APP_SERVICE_URL}/api/avatar/${user.id}`,
          },
        })
      );

      sendMessage('TeleLauncherSDK', 'OnGetBalance', +balance);

      cloudGetItem(storageKey.SAVE_DATA)
        .then(async (item) => {
          if (item !== null) {
            sendMessage('TeleLauncherSDK', 'OnSavesLoad', item);
          } else {
            const data = await getSave(
              window.Telegram.WebApp.initDataUnsafe.user.id || DEFAULT_USER.id,
              app_id
            );
            if (data?.data) {
              sendMessage(
                'TeleLauncherSDK',
                'OnSavesLoad',
                JSON.stringify(data.data)
              );
              cloudSetItem(storageKey.SAVE_DATA, JSON.stringify(data.data));
            }
          }
        })
        .catch((error) => {
          console.error('Error retrieving data from Telegram Storage:', error);
          sendMessage('TeleLauncherSDK', 'OnSavesLoad', '');
        });
    } else {
      console.warn('Telegram WebApp API is not available');
      sendMessage('TeleLauncherSDK', 'OnSavesLoad', '');
    }
  }, [balance, sendMessage, app_id]);

  useEffect(() => {
    if (isLoaded) {
      initializeApp();
      sendGTMEvent(GA4_KEY.unity_initializate);
    }
  }, [initializeApp, isLoaded]);

  useEffect(() => {
    const handleAppStarted = async (message) => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = message;

      const scripts = tempDiv.getElementsByTagName('script');
      const noscripts = tempDiv.getElementsByTagName('noscript');

      Array.from(scripts).forEach((originalScript) => {
        const script = document.createElement('script');
        script.type = originalScript.type;

        if (originalScript.src) {
          script.src = originalScript.src;
        }

        if (originalScript.innerHTML) {
          script.innerHTML = originalScript.innerHTML;
        }
        document.head.appendChild(script);
      });

      Array.from(noscripts).forEach((noscript) => {
        document.body.appendChild(noscript.cloneNode(true));
      });

      sendGTMEvent(GA4_KEY.unity_added_script);
    };

    addEventListener('AppStarted', handleAppStarted);
    return () => removeEventListener('AppStarted', handleAppStarted);
  }, [addEventListener, removeEventListener]);
};

export default useInitializeApp;
