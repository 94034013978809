import {
  useEffect,
  useState,
  // useState
} from 'react';
import useAdsgram from './useAdsgram';

// import { getBalance } from '../api/balance';

// import { DEFAULT_USER, GA4_KEY } from '../utils/constants';
// import { sendGTMEvent } from '../utils/analytics';

const useShowAds = (
  isLoaded,
  sendMessage,
  setIsModalVisible,
  setAdNumber,
  addEventListener,
  removeEventListener
) => {
  const showAd = useAdsgram();
  const [rewardResult, setRewardResult] = useState(null);
  useEffect(() => {
    if (!!isLoaded && !!rewardResult) {
      sendMessage(
        'TeleLauncherSDK',
        'OnAdsResult',
        JSON.stringify(rewardResult)
      );
      setRewardResult(null);
    }
  }, [isLoaded, rewardResult, sendMessage, setRewardResult]);

  useEffect(() => {
    const handleShowAdd = async (message) => {
      if (+message === 1) {
        const result = await showAd();
        setRewardResult(result);
      }
      setAdNumber(+message);
      if (+message === 2 || +message === 3) {
        setIsModalVisible(true);
      }
    };

    addEventListener('ShowAds', handleShowAdd);
    return () => removeEventListener('ShowAds', handleShowAdd);
  }, [
    addEventListener,
    removeEventListener,
    setIsModalVisible,
    setAdNumber,
    showAd,
  ]);
};

export default useShowAds;
