import makeRequest from './apiConfig';

const paymentEndpoints = {
  getPaymentProducts: '/api/products',
  createPayment: '/api/orders/create',
  getPaymentStatus: '/api/orders/status',
  validateOrder: '/api/orders/validate',
};

export const getPaymentProducts = async (products) => {
  try {
    const response = await makeRequest({
      method: 'POST',
      endpoint: paymentEndpoints.getPaymentProducts,
      body: {
        products: products,
      },
    });

    return response;
  } catch (error) {
    console.error('Failed to get payment products:', error);
    throw error;
  }
};

export const createPayment = async (productName, currencyItem) => {
  const searchParams = new URLSearchParams(window.location.search);
  const app_id = searchParams.get('app_id');
  try {
    const response = await makeRequest({
      method: 'POST',
      endpoint: paymentEndpoints.createPayment,
      body: {
        productName: productName.productId,
        ...(productName.payload && { payload: productName.payload }),
        app_id: app_id,
        stars: currencyItem === 'STARS',
        ...(currencyItem !== 'STARS' &&
          currencyItem !== 'TON' && { currency: currencyItem }),
      },
    });
    return response;
  } catch (error) {
    console.error('Failed to create payment products:', error);
    throw error;
  }
};

export const getPaymentStatus = async (hash) => {
  try {
    const response = await makeRequest({
      method: 'GET',
      endpoint: `${paymentEndpoints.getPaymentStatus}?order=${hash}`,
    });

    return response;
  } catch (error) {
    console.error('Failed to get payment status:', error);
    throw error;
  }
};

export const validateOrder = async (hash) => {
  try {
    const response = await makeRequest({
      method: 'POST',
      endpoint: paymentEndpoints.validateOrder,
      body: {
        order_hash: hash,
      },
    });
  } catch (error) {
    console.error('Failed to validate order:', error);
  }
};
