import { useCallback, useRef } from 'react';
import useUrlParams from './useUrlParams';
import GAMES_CONFIG from '../utils/GamesConfig';

const useAdsgram = () => {
  const AdControllerRef = useRef(undefined);
  const { app_id } = useUrlParams();

  const initAd = useCallback((blockId) => {
    if (window.Adsgram) {
      AdControllerRef.current = window.Adsgram.init({ blockId });
    }
  }, []);

  const showAd = useCallback(() => {
    initAd(GAMES_CONFIG[app_id || 1].adsgramKey);

    if (AdControllerRef.current) {
      return AdControllerRef.current
        .show()
        .then((result) => {
          return { ...result, placement: GAMES_CONFIG[app_id || 1].title };
        })
        .catch((result) => {
          return { ...result, placement: GAMES_CONFIG[app_id || 1].title };
        });
    } else {
      return {
        error: true,
        done: false,
        state: 'load',
        description: 'Adsgram script not loaded',
        placement: GAMES_CONFIG[app_id || 1].title,
      };
    }
  }, [initAd]);

  return showAd;
};

export default useAdsgram;
