import React, { useEffect, useState } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';

import { useDevicePixelRatio } from '../../hooks/useDevicePixelRatio';
import useUrlParams from '../../hooks/useUrlParams';

import GAMES_CONFIG from '../../utils/GamesConfig';
import { cloudGetItem, localGetItem } from '../../utils/cloudStorage';
import { storageKey } from '../../utils/constants';
import { checkTransactionStatus } from '../../utils/paymentsUtils';

import useGetBalance from '../../hooks/useGetBalance';
import useGetLeaderboardData from '../../hooks/useGetLeaderboardData';
import useGetProducts from '../../hooks/useGetProducts';
import useHapticTrigger from '../../hooks/useHapticTrigger';
import useInitializeApp from '../../hooks/useInitializeApp';
import useLoadData from '../../hooks/useLoadData';
import useLoadDataTelegram from '../../hooks/useLoadDataTelegram';
import useLoadTappadsTasks from '../../hooks/useLoadTappadsTasks';
import useOrderValidate from '../../hooks/useOrderValidate';
import usePaymentRequest from '../../hooks/usePaymentRequest';
import usePlayTappads from '../../hooks/usePlayTappads';
import useReloadApp from '../../hooks/useReloadApp';
import useSaveData from '../../hooks/useSaveData';
import useSaveDataTelegram from '../../hooks/useSaveDataTelegram';
import useSaveRefLinkToClipboard from '../../hooks/useSaveRefLinkToClipboard';
import useShare from '../../hooks/useShare';
import useShareToStory from '../../hooks/useShareToStory';
import useShowAds from '../../hooks/useShowAds';
import useShowRewardedAd from '../../hooks/useShowRewardedAd';

import Loading from '../../components/Loading/Loading';
import Popup from '../../components/Popup/Popup';
import Modal from '../../components/Modal/Modal';
import Tads from '../../components/Tads/Tads';
import Tappads from '../../components/Tappads/Tappads';

import LoadingLuckyVerse from '../../gamesSrc/luckyVerse/componentsLuckyVerse/LoadingLuckyVerse/LoadingLuckyVerse';

import styles from './GamePage.module.css';

function GamePage({ initialBalance = null, isVerticalOrientation }) {
  const { app_id } = useUrlParams();
  const devicePixelRatio = useDevicePixelRatio();

  const [balance, setBalance] = useState(initialBalance);
  const [paymentProduct, setPaymentProduct] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [AdNumber, setAdNumber] = useState(null);

  const [fullLoaded, setfullLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showConnectWallet, setShowConnectWallet] = useState(false);

  const handleCacheControl = (url) => {
    if (
      url.match(/\.data/) ||
      url.match(/\.loader/) ||
      url.match(/\.framework/) ||
      url.match(/\.wasm/)
    ) {
      const cache = localGetItem(`${storageKey.UNITY_CACHE}${app_id}`);
      return !cache ? 'must-revalidate' : 'immutable';
    }
  };

  const {
    unityProvider,
    loadingProgression,
    isLoaded,
    sendMessage,
    addEventListener,
    removeEventListener,
  } = useUnityContext({
    ...GAMES_CONFIG[app_id].gameUrls,

    webglContextAttributes: {
      alpha: false, // Отключаем прозрачность
      antialias: false, // Отключаем сглаживание
      powerPreference: 'high-performance',
      preserveDrawingBuffer: false,
      premultipliedAlpha: false,
      desynchronized: true, // Уменьшает задержку рендеринга
      failIfMajorPerformanceCaveat: false,
    },
    productVersion: '1.0',
    devicePixelRatio,
    cacheControl: handleCacheControl,
  });

  useEffect(() => {
    const tg = window.Telegram.WebApp;

    const handleSafeAreaChange = () => {
      sendMessage(
        'TeleLauncherSDK',
        'OnSafeAreaUpdated',
        JSON.stringify({
          contentSafeAreaInset: tg.contentSafeAreaInset,
          safeAreaInset: tg.safeAreaInset,
        })
      );
    };

    const handleContentSafeAreaChange = () => {
      sendMessage(
        'TeleLauncherSDK',
        'OnSafeAreaUpdated',
        JSON.stringify({
          contentSafeAreaInset: tg.contentSafeAreaInset,
          safeAreaInset: tg.safeAreaInset,
        })
      );
    };
    // };

    tg.onEvent('safeAreaChanged', handleSafeAreaChange);
    tg.onEvent('contentSafeAreaChanged', handleContentSafeAreaChange);

    return () => {
      tg.offEvent('safeAreaChanged', handleSafeAreaChange);
      tg.offEvent('contentSafeAreaChanged', handleContentSafeAreaChange);
    };
  }, [sendMessage]);

  useEffect(() => {
    if (isLoaded) {
      cloudGetItem(storageKey.PAYMENT_HASH).then(async (item) => {
        if (item && item?.hash && item?.paymentProduct) {
          setIsVisible(true);
          setIsLoading(true);
          setPaymentProduct(item.paymentProduct);
          await checkTransactionStatus(
            item.hash,
            item.paymentProduct,
            setPaymentProduct,
            sendMessage,
            setIsLoading,
            setIsVisible
          );
        }
      });
    }
  }, [isLoaded, sendMessage, setIsLoading, setIsVisible]);

  useInitializeApp(
    isLoaded,
    sendMessage,
    balance,
    addEventListener,
    removeEventListener
  );
  useReloadApp(addEventListener, removeEventListener);
  useGetProducts(isLoaded, sendMessage, addEventListener, removeEventListener);
  useGetBalance(
    isLoaded,
    sendMessage,
    balance,
    setBalance,
    addEventListener,
    removeEventListener
  );
  useShare(addEventListener, removeEventListener);
  useShareToStory(addEventListener, removeEventListener);
  useSaveRefLinkToClipboard(addEventListener, removeEventListener);
  useGetLeaderboardData(
    isLoaded,
    sendMessage,
    addEventListener,
    removeEventListener
  );
  useLoadData(isLoaded, sendMessage, addEventListener, removeEventListener);
  useSaveData(addEventListener, removeEventListener);
  useSaveDataTelegram(addEventListener, removeEventListener);
  useLoadDataTelegram(
    isLoaded,
    sendMessage,
    addEventListener,
    removeEventListener
  );
  usePaymentRequest(
    setPaymentProduct,
    setIsVisible,
    setShowConnectWallet,
    addEventListener,
    removeEventListener
  );
  useShowRewardedAd(
    isLoaded,
    sendMessage,
    addEventListener,
    removeEventListener
  );
  usePlayTappads(addEventListener, removeEventListener);
  useLoadTappadsTasks(
    isLoaded,
    sendMessage,
    addEventListener,
    removeEventListener
  );
  useShowAds(
    isLoaded,
    sendMessage,
    setIsModalVisible,
    setAdNumber,
    addEventListener,
    removeEventListener
  );
  useOrderValidate(addEventListener, removeEventListener);
  useHapticTrigger(addEventListener, removeEventListener);

  return (
    <div className={styles.container}>
      {!fullLoaded &&
        (+app_id === 1 ? (
          <LoadingLuckyVerse
            setBalance={setBalance}
            gameProgress={Math.floor(loadingProgression * 100)}
            setFullLoaded={setfullLoaded}
            isVerticalOrientation={isVerticalOrientation}
          />
        ) : (
          <Loading
            setBalance={setBalance}
            gameProgress={Math.floor(loadingProgression * 100)}
            setFullLoaded={setfullLoaded}
          />
        ))}
      <Unity
        unityProvider={unityProvider}
        devicePixelRatio={devicePixelRatio}
        className={styles.unityContainer}
      />
      <div className={styles.popupContainer}>
        <Popup
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          sendMessage={sendMessage}
          paymentProduct={paymentProduct}
          setPaymentProduct={setPaymentProduct}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          showConnectWallet={showConnectWallet}
          setShowConnectWallet={setShowConnectWallet}
        />
      </div>
      <Modal
        isVisible={isModalVisible}
        onClose={() => {
          sendMessage(
            'TeleLauncherSDK',
            'OnAdsResult',
            JSON.stringify({
              done: false,
            })
          );
          setIsModalVisible(false);
          setAdNumber(null);
        }}
        title='Choose your ad'
      >
        {AdNumber === 2 && (
          <Tads
            setIsModalVisible={setIsModalVisible}
            setAdNumber={setAdNumber}
            sendMessage={sendMessage}
          />
        )}
        {AdNumber === 3 && (
          <Tappads
            setIsModalVisible={setIsModalVisible}
            setAdNumber={setAdNumber}
            sendMessage={sendMessage}
          />
        )}
      </Modal>
    </div>
  );
}

export default GamePage;
