import { useEffect } from 'react';

import { validateOrder } from '../api/payments';

const useOrderValidate = (addEventListener, removeEventListener) => {
  useEffect(() => {
    const handlePaymentRequest = (message) => {
      validateOrder(message);
    };

    addEventListener('OrderValidate', handlePaymentRequest);
    return () => removeEventListener('OrderValidate', handlePaymentRequest);
  }, [addEventListener, removeEventListener]);
};

export default useOrderValidate;
