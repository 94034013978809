import { useEffect } from 'react';

const useReloadApp = (addEventListener, removeEventListener) => {
  useEffect(() => {
    const handleReloadApp = () => {
      window.location.reload();
    };
    addEventListener('ReloadApp', handleReloadApp);

    return () => {
      removeEventListener('ReloadApp', handleReloadApp);
    };
  }, [addEventListener, removeEventListener]);
};

export default useReloadApp;
