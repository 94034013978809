const telegram = window.Telegram.WebApp;
const platform = telegram.platform;
const isDesktop = telegram
  ? ['tdesktop', 'macos', 'weba'].includes(platform)
    ? 'Desktop'
    : 'Mobile'
  : 'Mobile';

const GAMES_CONFIG = {
  1: {
    gameUrls: {
      loaderUrl: `https://lucky-verse-dev-webgl.s3.eu-central-1.amazonaws.com/Assets/2024.3.8/${isDesktop}/Build/webgl.loader.js`,
      dataUrl: `https://lucky-verse-dev-webgl.s3.eu-central-1.amazonaws.com/Assets/2024.3.8/${isDesktop}/Build/webgl.data.br`,
      frameworkUrl: `https://lucky-verse-dev-webgl.s3.eu-central-1.amazonaws.com/Assets/2024.3.8/${isDesktop}/Build/webgl.framework.js.br`,
      codeUrl: `https://lucky-verse-dev-webgl.s3.eu-central-1.amazonaws.com/Assets/2024.3.8/${isDesktop}/Build/webgl.wasm.br`,
      streamingAssetsUrl: `https://lucky-verse-dev-webgl.s3.eu-central-1.amazonaws.com/Assets/2024.3.8/${isDesktop}/StreamingAssets`,
    },
    assertsUrl: '',
    logo: '/logo.jpg',
    title: 'LUCKY VERSE',
    subtitle: 'BY PLAYTIVITI',
    telegramUrl: 'https://t.me/LuckyVerseBot',
    shareDefaultText: 'Get 0.2 $LUCK 🍀 by join LuckyVerse!',
    diamond: 'Get 0.2 $LUCK 🍀 by join LuckyVerse!',
    paymentCurrencies: [
      'STARS',
      // 'SOL',
      // 'TOKEN',
      'TON',
      // 'Notcoin',
      // 'Hamster Kombat',
      // 'Catizen',
      // 'Tether USDt',
    ],
    disabledCurrencies: ['SOL', 'TOKEN'],
    adsgramKey: '7406',
    fullScreen: true,
  },
  2: {
    gameUrls: {
      loaderUrl:
        'https://games.telelauncher.com/kill-them-all-gzip/public/Build/KillThemAll.loader.js',
      dataUrl:
        'https://games.telelauncher.com/kill-them-all-gzip/public/Build/KillThemAll.data.unityweb',
      frameworkUrl:
        'https://games.telelauncher.com/kill-them-all-gzip/public/Build/KillThemAll.framework.js.unityweb',
      codeUrl:
        'https://games.telelauncher.com/kill-them-all-gzip/public/Build/KillThemAll.wasm.unityweb',
    },
    assertsUrl: 'https://games.telelauncher.com/kill-them-all-gzip/public',
    logo: 'https://raw.githubusercontent.com/dangost/static/refs/heads/master/ST-icon-1.png',
    title: 'Kill Them All',
    subtitle: 'BY PANDAPLAY',
    telegramUrl: 'https://t.me/kill_them_all_bot',
    shareDefaultText: 'Welcome to our application',
    diamond: 'Get 1$ or 50 💎 for FREE by join Kill Them All!',
    paymentCurrencies: ['STARS', 'SOL', 'TOKEN', 'TON'],
    disabledCurrencies: ['SOL', 'TOKEN'],
    adsgramKey: '4167',
    fullScreen: false,
  },
  3: {
    gameUrls: {
      loaderUrl:
        'https://games.telelauncher.com/testgame-gzip/Build/TeleLauncherUnity-Gzip.loader.js',
      dataUrl:
        'https://games.telelauncher.com/testgame-gzip//Build/TeleLauncherUnity-Gzip.data.unityweb',
      frameworkUrl:
        'https://games.telelauncher.com/testgame-gzip//Build/TeleLauncherUnity-Gzip.framework.js.unityweb',
      codeUrl:
        'https://games.telelauncher.com/testgame-gzip//Build/TeleLauncherUnity-Gzip.wasm.unityweb',
    },
    assertsUrl: '',
    logo: '/logo.jpg',
    title: 'TIME MASTER',
    subtitle: 'BY PLAYTIVITI',
    telegramUrl: 'https://t.me/TimeMasterCoinBot',
    shareDefaultText: 'Welcome to our application',
    diamond: 'Get 1$ or 50 💎 for FREE by join Time Master!',
    paymentCurrencies: [
      'STARS',
      // 'SOL',
      // 'TOKEN',
      'TON',
      'Notcoin',
      'Hamster Kombat',
      'Catizen',
      'Tether USDt',
    ],
    disabledCurrencies: [],
    adsgramKey: '4167',
    fullScreen: false,
  },
};

export default GAMES_CONFIG;
