import {
  TonConnectButton,
  useTonAddress,
  useTonConnectModal,
  useTonConnectUI,
} from '@tonconnect/ui-react';
import React, { useEffect, useState } from 'react';

import GAMES_CONFIG from '../../utils/GamesConfig';
import { sendGTMEvent } from '../../utils/analytics';
import { cloudGetItem } from '../../utils/cloudStorage';
import { GA4_KEY, gamesCurrencies, storageKey } from '../../utils/constants';
import { getStarsPayment, getTonPayment } from '../../utils/paymentsUtils';

import Loader from '../Loader/Loader';

import useUrlParams from '../../hooks/useUrlParams';

import './Popup.css';

const Popup = ({
  isVisible,
  setIsVisible,
  sendMessage,
  paymentProduct,
  setPaymentProduct,
  isLoading,
  setIsLoading,
  showConnectWallet,
  setShowConnectWallet,
}) => {
  const { app_id } = useUrlParams();
  const [tonConnectUI] = useTonConnectUI();
  const { state, open, close } = useTonConnectModal();
  const userAddress = useTonAddress();
  const tonAddress = useTonAddress(true);

  function localRemoveItem(key) {
    localStorage.removeItem(key);
  }

  function localItem(key, callback = null) {
    const itemString = localStorage.getItem(key);
    if (!itemString) {
      return null;
    }
    const item = JSON.parse(itemString);
    const now = Date.now();
    if (item.expiry && now > item.expiry) {
      if (callback) {
        callback(item.value);
      }
      localRemoveItem(key);
      return null;
    }
    return item.value;
  }

  const [
    token,
    // setToken
  ] = useState(localItem('Token'));

  const togglePopup = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    }
    cloudGetItem(storageKey.PAYMENT_HASH).then(async (item) => {
      if (!item) {
        setIsVisible(!isVisible);
        sendMessage(
          'TeleLauncherSDK',
          'OnPurchaseResult',
          JSON.stringify({
            product_id: paymentProduct.productId,
            ...(paymentProduct.payload && { payload: paymentProduct.payload }),
            status: 1,
            hash: '',
          })
        );
      }
    });
  };

  useEffect(() => {
    if (tonAddress && token) {
      const userId = window.Telegram.WebApp.initDataUnsafe.user?.id;
      if (userId) {
        fetch(`${process.env.REACT_APP_SERVICE_URL}/api/connect-wallet`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: userId, ton_address: tonAddress }),
        });
      }
    }
  }, [tonAddress, token]);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isVisible]);

  useEffect(() => {
    if (userAddress && showConnectWallet) {
      setShowConnectWallet(false);
      getTonPayment(
        paymentProduct,
        setPaymentProduct,
        sendMessage,
        tonConnectUI,
        setIsLoading,
        setIsVisible
      );
    }
  }, [
    userAddress,
    showConnectWallet,
    setShowConnectWallet,
    paymentProduct,
    setPaymentProduct,
    sendMessage,
    tonConnectUI,
    setIsLoading,
    setIsVisible,
  ]);

  const paymentRequest = async (currency = '') => {
    try {
      if (currency === 'STARS') {
        getStarsPayment(
          paymentProduct,
          setPaymentProduct,
          sendMessage,
          setIsLoading,
          setIsVisible,
          currency
        );
      } else {
        if (!userAddress) {
          setShowConnectWallet(true);
          return;
        }
        getTonPayment(
          paymentProduct,
          setPaymentProduct,
          sendMessage,
          tonConnectUI,
          setIsLoading,
          setIsVisible,
          currency
        );
      }
    } catch (error) {
      console.error('Error creating order:', error);
      alert('Failed to create order. Please try again.');
    }
  };

  return (
    <div className={`popup ${isVisible ? 'visible' : ''}`}>
      {showConnectWallet ? (
        <>
          <div className='popup-header'>
            <button className='close-popup' onClick={togglePopup}>
              &times;
            </button>
            <button
              className='back-popup'
              onClick={() => {
                if (window.Telegram && window.Telegram.WebApp) {
                  window.Telegram.WebApp.HapticFeedback.impactOccurred(
                    'medium'
                  );
                }
                setShowConnectWallet(false);
              }}
            >
              &#8592;
            </button>
          </div>
          <div className='popup-content'>
            <div className='connect-wallet-container'>
              <p>Please connect your wallet to proceed with TON payment</p>
              <TonConnectButton />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='popup-header'>
            {userAddress && (
              <div className='wallet-button'>
                <TonConnectButton />
              </div>
            )}
            <button className='close-popup' onClick={togglePopup}>
              &times;
            </button>
            <div className='popup-title'>Choose Payment Method</div>
          </div>

          <div className='popup-content'>
            {isLoading && <Loader message='Processing payment...' />}
            {!isLoading && (
              <>
                {GAMES_CONFIG[+app_id || 1].paymentCurrencies.map(
                  (currency) => {
                    return (
                      <button
                        className={`popup-button
                          ${
                            gamesCurrencies[currency].className
                              ? `${gamesCurrencies[currency].className}-button`
                              : ''
                          }
                          `}
                        disabled={GAMES_CONFIG[
                          +app_id || 1
                        ].disabledCurrencies.includes(
                          gamesCurrencies[currency].code
                        )}
                        key={gamesCurrencies[currency].name}
                        onClick={() => {
                          if (window.Telegram && window.Telegram.WebApp) {
                            window.Telegram.WebApp.HapticFeedback.impactOccurred(
                              'medium'
                            );
                          }
                          paymentRequest(gamesCurrencies[currency].code);
                          sendGTMEvent(
                            GA4_KEY.payment(paymentProduct.productId, false)
                          );
                        }}
                      >
                        <span>Buy with {gamesCurrencies[currency].name}</span>

                        {gamesCurrencies[currency].symbol && (
                          <div className='symbol'>
                            {gamesCurrencies[currency].symbol}
                          </div>
                        )}
                      </button>
                    );
                  }
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Popup;
